<template>
	<div class="newsletter">
		<div class="container">
			<div class="h1 mb-3">An email newsletter without added sulfites.</div>
			<div>
				<div class="text-muted mb-5">Receive occasional emails about new features, promotions, and resources from the Amino team. No spam, ever.</div>
				<form action="https://aminoeditor.us7.list-manage.com/subscribe/post?u=a489bd9f9484e328acd918217&amp;id=9d136ca080" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
					target="_blank" novalidate="">
					<b-input-group class="d-flex flex-row mt-3">
						<b-form-input placeholder="Enter your email address" style="border-top-right-radius: 0; border-bottom-right-radius: 0;" type="email" name="EMAIL"></b-form-input>
						<b-input-group-append>
							<b-button style="height: 100%;" variant="primary" size="sm" type="submit">
								Sign Up
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Newsletter',
    data() {
        return {};
    }
}
</script>

<style lang="scss" scoped>
	.newsletter {
		padding: 7rem 0;
	}
	.container {
		max-width: 500px;
	}
</style>
